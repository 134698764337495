import { useState, useEffect } from 'react'

import { useChatContext } from '../../../pages/Protected/Chats'
import ChatTopBar from './ChatTopBar'
import ChatScrollArea from './ChatScrollArea'
import ChatTypeBar from './ChatTypeBar'
import Body from '../../typography/Body'
import SearchBar from '../../layout/SearchBar'
import defaultProfilePhoto from '../../../assets/images/profile.jpg'
import pattern from '../../../assets/images/pattern.png'
import { getMessages } from '../../../api/chats'
import { fetchProfilePhoto } from '../../../api/users'

let chatLength, nextPage, isSelected, isLoading, currentChat, lastMessageFromRender, searchTerm = ''
const ChatScreen = () => {
  const {
    account,
    setError,
    chat, setChat,
    shouldRefreshChat, setShouldRefreshChat,
    isSearchBarVisible,
    isCameraActive,
    setProfilePhotoMap, profilePhotoMapSyncControl,
    isOptionsMenuVisible, setIsOptionsMenuVisible } = useChatContext()

  const [chatContent, setChatContent] = useState([])
  const renderTypeBar = Boolean(!isCameraActive && chat.producer?.account?.responsibles?.includes(account.email))

  const fetchMessages = async () => {
    getMessages({ 
      producerAccountId: chat.producerAccountId,
      page: nextPage,
      orderBy: 'chatContentCreationDate',
      orderDirection: 'desc',
      search: searchTerm || undefined,
      sentBeforeOrWithMessage: lastMessageFromRender
    })
      .then(response => {
        if (response.links.metaData.total === 0) {
          isLoading = false
          setChatContent([])
          return 
        }

        if (!chatLength) {
          chatLength = response.links.metaData.total
          lastMessageFromRender = response.data[0]?.id
        }

        if (chatContent.length <= response.links.metaData.total) {
          nextPage += 1
          setChatContent([...response.data.reverse(), ...chatContent])
          getProfilePhotos(response.data)
        }
      })
      .catch(e => {
        setError(e.message)
        setChat({})
      })
  }

  if (!chat.producerAccountId) {
    isSelected = false
  }

  if (chat.producerAccountId && chat.producerAccountId !== currentChat) {
    currentChat = chat.producerAccountId
    setChatContent([])
  }

  if (chat.producerAccountId && !chatContent.length) {
    chatLength = 0
    nextPage = 1
    isSelected = true
    isLoading = true
    lastMessageFromRender = undefined
    fetchMessages()
  }

  if (chat.producerAccountId && chatContent.length) {
    isLoading = false
  }

  const getProfilePhotos = async (messageList) => {
    const promises = messageList.map(async (message) => {
      if (message.type === 'changeLog') return
  
      if (!profilePhotoMapSyncControl.has(message.sender.id)) {
        profilePhotoMapSyncControl.add(message.sender.id)

        if (!message.sender.profilePhoto) {
          setProfilePhotoMap(prev => ({ ...prev, [message.sender.id]: defaultProfilePhoto }))
          return
        }
  
        const response = await fetchProfilePhoto(message.sender.profilePhoto)
        setProfilePhotoMap(prev => ({ ...prev, [message.sender.id]: response || defaultProfilePhoto }))
      }
    })
  
    await Promise.all(promises)
  }

  useEffect(() => {
    if (shouldRefreshChat) {
      setShouldRefreshChat(false)
      setChatContent([])
    }
  }, [chat, chatContent, shouldRefreshChat])

  const handleSearchMessages = (search) => {
    searchTerm = search
    isLoading = true
    page = 1
    setChatContent([])
  }

  return (
    <div className='flex w-full grow bg-green-1'>
      <div
        className='flex-1 flex flex-col w-full grow bg-repeat'
        style={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: '375px',
        }}
      >
        { isSelected ? (
          <div className='flex grow w-full flex-col max-h-screen' onClick={() => { if (isOptionsMenuVisible) setIsOptionsMenuVisible(false) }}>
            <div className='flex flex-row justify-between w-full'>
              <ChatTopBar />
            </div>

            {isSearchBarVisible && !isCameraActive && (
              <div className='flex flex-row justify-start items-center bg-white-1 p-sm gap-xxs' >
                <SearchBar
                  className='w-full'
                  onSearch={handleSearchMessages}
                  initialSearchTerm={''}
                />
              </div>
            )}

            {isLoading && ( 
                <div className='flex items-center px-sm justify-center h-full w-full'>
                  Carregando...
                </div>
            )}

            {!isLoading && (
              <ChatScrollArea
                chatContent={chatContent}
                setChatContent={setChatContent}
                fetchMessages={fetchMessages}
                chatLength={chatLength}
              />
            )}

            {renderTypeBar && (
              <div className='justify-self-end'>
                <ChatTypeBar setChatContent={setChatContent} />
              </div>                  
            )}
          </div>
          ) : (
            <div className='flex items-center justify-center w-full h-full'>
              <Body size='lg' style='text-center'>
                Nenhum chat selecionado
              </Body>
            </div>
          )}
      </div>
    </div>
  )
}

export default ChatScreen
