import { GetContentsParams, Content, ContentBody, APIResponse } from '../types';
import { sanitizeObject, setDefaultsForPagination } from './_helpers';
import { getSessionData, getToken } from './auth';
const BASE_URL = '/api';

/**
 * @param {GetContentsParams} params 
 * @returns {Promise<APIResponse>}
 */
export const getContents = async (params) => {
  try {
    setDefaultsForPagination(params)
    sanitizeObject(params, GetContentsParams)

    const token = await getToken();
    const parameters = new URLSearchParams(params).toString();

    const response = await fetch(`${BASE_URL}/content?${parameters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar conteúdos');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar conteúdos:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}

/**
 * @param {GetContentsParams} params 
 * @returns {Promise<string>}
 */
export const downloadContentsCSV = async (params) => {
  try {
    setDefaultsForPagination(params)
    sanitizeObject(params, GetContentsParams)

    const token = await getToken();
    const parameters = new URLSearchParams(params).toString();

    const response = await fetch(`${BASE_URL}/content/csv?${parameters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar conteúdos');
    }

    const result = await response.text();
    return result;
  } catch (error) {
    console.error('Erro ao buscar conteúdos:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}

/**
 * @returns {Promise<APIResponse>}
 */
export const getContentReferences = async () => {
  try {
    const token = await getToken();
    const response = await fetch(`${BASE_URL}/content/reference`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar dados de referência para tutoriais');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar dados de referência para tutoriais:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}

/**
 * 
 * @param {uuid} contentId 
 * @returns {Promise<APIResponse>}
 */
export const getContentById = async (contentId) => {
  try {
    const token = await getToken();

    const response = await fetch(`${BASE_URL}/content/${contentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar tutorial');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar tutorial:', error);
    return { result: null };
  };
};

/**
 * 
 * @param {ContentBody} contentBody 
 * @returns {Promise<APIResponse>}
 */
export const createContent = async (contentBody) => {
  try {
    const token = await getToken();
    const sessionData = getSessionData();
    const agent = sessionData?.users[0]?.id;

    sanitizeObject(contentBody.content, Content)
    
    const response = await fetch(`${BASE_URL}/content/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        agent
      },
      body: JSON.stringify(contentBody),
    });

    if (!response.ok) {
      throw new Error('Erro ao criar tutorial');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao criar tutorial:', error);
    return null;
  }
}

/**
 * 
 * @param {uuid} contentId 
 * @param {ContentBody} contentBody 
 * @returns {Promise<APIResponse>}
 */
export const updateContent = async (contentId, contentBody) => {
  try {
    const token = await getToken();
    const sessionData = getSessionData();
    const agent = sessionData?.users[0]?.id;

    sanitizeObject(contentBody.content, Content)
    
    const response = await fetch(`${BASE_URL}/content/${contentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        agent
      },
      body: JSON.stringify(contentBody),
    });

    if (!response.ok) {
      throw new Error('Erro ao editar tutorial');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao editar tutorial:', error);
    return null;
  }
}