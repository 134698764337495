
import { useState, useEffect } from 'react'

import { useChatContext } from '../../../pages/Protected/Chats'
import Chat from './Chat'
import Display from '../../typography/Display'
import SearchBar from '../../layout/SearchBar'
import defaultProfilePhoto from '../../../assets/images/profile.jpg'
import { getChats } from '../../../api/chats'
import { fetchProfilePhoto } from '../../../api/users'

const ChatList = () => {
  const {
    setError,
    setProfilePhotoMap, profilePhotoMapSyncControl,
    chatList, setChatList
  } = useChatContext()
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [listLength, setListLength] = useState(0)
  const [searching, setIsSearching] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const fetchChats = async (search) => {
    getChats({
      orderBy: 'messageCreationDate',
      orderDirection: 'desc',
      page: page,
      search
    })
      .then(response => {
        if (response.links.metaData.total === 0) {
          setIsSearching(false)
          setIsLoading(false)
          return 
        }

        if (!listLength) {
          setListLength(response.links.metaData.total)
        }

        if (chatList.length < response.links.metaData.total) {
          setPage(page + 1)
          
          const priorized = new Array()
          const unpriorized = new Array()
          response.data.forEach(chat => {
            if (chat.servicePriorityLevel === 'alta') priorized.push(chat)
            else unpriorized.push(chat)
          })

          const orderedResults = [...priorized, ...unpriorized]
          setChatList([...chatList, ...orderedResults])
          getProfilePhotos(orderedResults)
        }
      })
      .catch(e => {
        setError(e.message)
        setChatList([])
      })
  }

  const getProfilePhotos = async (chatList) => {
    const promises = chatList.map(async (chat) => {
      if (!profilePhotoMapSyncControl.has(chat.producer.user.id)) {
        profilePhotoMapSyncControl.add(chat.producer.user.id)
  
        if (!chat.producer.user.profilePhoto) {
          setProfilePhotoMap(prev => ({...prev, [chat.producer.user.id]: defaultProfilePhoto}))
          return
        }
  
        const response = await fetchProfilePhoto(chat.producer.user.profilePhoto)
        setProfilePhotoMap(prev => ({ ...prev, [chat.producer.user.id]: response || defaultProfilePhoto}))
      }
    })
  
    await Promise.all(promises)
  }

  const searchChats = async (searchTerm) => {
    setIsSearching(true)
    setIsLoading(true)
    setChatList([])
    setPage(1)
    setSearchTerm(searchTerm)
  }

  useEffect(() => {
    if (searchTerm && searching && isLoading && page === 1 & !chatList.length) {
      fetchChats(searchTerm)
    }
  }, [chatList, page, isLoading, searching, searchTerm])

  useEffect(() => {
    if (!searchTerm) {
      setIsSearching(false)
      fetchChats()
    }
  }, [searchTerm])

  useEffect(() => {
    if (!chatList.length && !searching) {
      fetchChats()
    }

    if (chatList.length) {
      setIsLoading(false)
    }
  }, [chatList])

  return (
    <div className='flex flex-col w-full h-full'>
      {/* Section display */}
      <div className='flex justify-between p-sm'>
        <Display size='md' style='text-green-4'>Chats</Display>
      </div>

      {/* SearchBar */}
      <div className='flex w-full px-sm pb-sm'>
        <SearchBar
          className='w-full'
          onSearch={searchChats}
          initialSearchTerm={''}
        />
      </div>

      {/* List */}
      <div className='flex w-full grow overflow-y-scroll gap-lg'>
        {isLoading ? (
          <div className='flex items-center px-sm justify-center h-full w-full'>
            Carregando...
          </div>
        ) : (
          <div className='flex grow flex-col w-full overflow-y-scroll self-start'>
            {(Array.isArray(chatList) && chatList.length && chatList.map((item, index) => (
              <div key={index}>
                <Chat chat={item} />
              </div>
            ))) || 
              <div className='flex items-center justify-center h-full'>
                Nenhum chat encontrado
              </div>
            }
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatList