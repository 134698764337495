import { GetAppointmentsParams, APIResponse } from '../types';
import { getToken } from './auth';
import { sanitizeObject, setDefaultsForPagination } from './_helpers';
const BASE_URL = '/api';

/**
 * @param {GetAppointmentsParams} params 
 * @returns {Promise<APIResponse>}
 */
export async function getAppointments (params) {
  try {
    setDefaultsForPagination(params)
    sanitizeObject(params, GetAppointmentsParams)
    
    const token = await getToken();
    const parameters = new URLSearchParams(params).toString();

    const response = await fetch(`${BASE_URL}/appointment?${parameters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar visitas');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar visitas:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}

/**
 * @returns {Promise<APIResponse>}
 */
export const getAppointmentReferences = async () => {
  try {
    const token = await getToken();
    const response = await fetch(`${BASE_URL}/appointment/reference`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar dados de referência para visitas');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar dados de referência para visitas:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}