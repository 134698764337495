import { GetServicesParams, APIResponse } from '../types';
import { sanitizeObject, setDefaultsForPagination } from './_helpers';
import { getToken } from './auth';
const BASE_URL = '/api';

/**
 * @param {GetServicesParams} params 
 * @returns {Promise<APIResponse>}
 */
export const getServices = async (params) => {
  try {
    setDefaultsForPagination(params)
    sanitizeObject(params, GetServicesParams)

    const token = await getToken();
    const parameters = new URLSearchParams(params).toString()

    const response = await fetch(`${BASE_URL}/chat/service?${parameters}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao buscar atendimentos');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Erro ao buscar atendimentos:', error);
    return { data: [], links: { metaData: { total: 0, lastPage: 0 } } };
  }
}