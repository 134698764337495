import React, { useState, useEffect, useRef } from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import Display from '../../components/typography/Display';
import ButtonPrimary from '../../components/actions/ButtonPrimary';
import Label from '../../components/typography/Label';
import Body from '../../components/typography/Body';
import Table from '../../components/layout/Table';
import Modal from '../../components/overlay/Modal';
import InputText from '../../components/inputs/InputText';
import InputNumber from '../../components/inputs/InputNumber';
import InputPassword from '../../components/inputs/InputPassword';
import InputCoverPhoto from '../../components/inputs/InputCoverPhoto';
import FormError from '../../components/inputs/FormError';
import InputSelect from '../../components/inputs/InputSelect';
import InputProfilePhoto from '../../components/inputs/InputProfilePhoto';
import { getUsers, getProperties, getUser, getAccount, putUser, putUserProfilePhoto, postAccount, postUser, getReferences, fetchCoverPhoto, fetchProfilePhoto, downloadPropertiesCSV, downloadUsersCSV } from '../../api/users';

const Properties = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [references, setReferences] = useState(null);
  const [responsibles, setResponsibles] = useState([]);
  const [propertyData, setPropertyData] = useState({});
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [filter, setFilter] = useState('');

  const loadProperties = async (page, search = searchTerm, filter = '') => {
    setIsTableLoading(true);

    let params = {
      page,
      limit: 20,
      search: search || undefined,
      entity: filter || undefined,
    };

    params = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined && v !== '')
    );

    const result = await getProperties(params);

    setData(result.data);
    setTotalItems(result.links.metaData.total);
    setTotalPages(result.links.metaData.lastPage);
    setIsTableLoading(false);
  };
  
  const openCreateModal = async () => {
    setIsCreateModalOpen(true);
    const refData = await getReferences();
    setReferences(refData);

    const technicians = await getUsers({ role: 'technician', limit: 50 });
    const responsiblesOptions = technicians.data.map(tech => ({ id: tech.email, value: tech.email }));
    setResponsibles(responsiblesOptions);
  };

  const openEditModal = async (accountId) => {
    setSelectedAccountId(accountId);
    setIsEditModalOpen(true);

    const refData = await getReferences();
    setReferences(refData);

    const technicians = await getUsers({ role: 'technician', limit: 50 });
    const responsiblesOptions = technicians.data.map(tech => ({ id: tech.email, value: tech.email }));
    setResponsibles(responsiblesOptions);
  };
  
  const closeModal = () => {
    setIsCreateModalOpen(false);
    setIsEditModalOpen(false);
    setPropertyData({});
    setSelectedAccountId(null);
  };

  useEffect(() => {
    loadProperties(currentPage);
  }, [currentPage]);

  const columns = [
    { Header: 'Propriedade', accessor: 'alias' },
    { Header: 'Beneficiário', accessor: 'beneficiaryName' },
    { Header: 'Entidade', accessor: 'entity' },
  ];

  const actions = [
    { label: 'Editar', onClick: (row) => openEditModal(row.producerAccountId) },
  ];

  const filterEntities = [
    { value: '', label: 'Selecione uma entidade' },
    { value: 'IPAM', label: 'IPAM' },
    { value: 'FVPP', label: 'FVPP' }
  ];

  const entities = [
    { value: 'IPAM', label: 'IPAM' },
    { value: 'FVPP', label: 'FVPP' }
  ];

  const cleanBody = (body) => {
    return Object.entries(body).reduce((acc, [key, value]) => {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        const cleanedValue = cleanBody(value);
        if (Object.keys(cleanedValue).length > 0) {
          acc[key] = cleanedValue;
        }
      } else if (value !== null && value !== undefined && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const handleSearch = async (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
    await loadProperties(1, term, filter);
  };
  
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
    loadProperties(1, searchTerm, newFilter);
  };    

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handleDownloadCSV = async () => {
    const csvData1 = await downloadPropertiesCSV(searchTerm);
    const csvData2 = await downloadUsersCSV('producer', searchTerm);
    if (csvData1) {
      const blob = new Blob([csvData1], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'properties.csv';
      a.click();
      window.URL.revokeObjectURL(url);

      if (csvData2) {
        const blob = new Blob([csvData2], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'beneficiariesAndFamilyMembers.csv';
        a.click();
        window.URL.revokeObjectURL(url);
      } 
    } else {
    }
  };

  return (
    <div className="flex flex-col w-full h-max p-xl gap-lg">
      <div className='flex justify-between'>
        <Display size='md' style="text-green-4">Propriedades e Beneficiários</Display>
        <div className='flex gap-xs'>
          <ButtonPrimary
            text="Cadastrar Propriedade"
            icon='group_add'
            left
            onClick={openCreateModal}
          />
          <ButtonPrimary
            icon='download'
            left
            onClick={handleDownloadCSV}
          />
        </div>
      </div>
      <div className='flex flex-col gap-sm'>

        {isTableLoading ? (
          <div className="flex items-center justify-center h-full">
            <span>Carregando...</span>
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            actions={actions}
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            onSearch={handleSearch}
            onFilterChange={handleFilterChange}
            filterOptions={filterEntities}
            defaultFilterValue=""
            searchTerm={searchTerm}
            filter={filter}
          />
        )}

        {isCreateModalOpen && (
          <CreatePropertyModal
            cleanBody={cleanBody}
            references={references}
            responsibles={responsibles}
            entities={entities}
            onClose={closeModal}
            onSave={() => {
              closeModal();
              loadProperties(currentPage);
            }}
          />
        )}

        {isEditModalOpen && selectedAccountId && (
          <EditPropertyModal
            cleanBody={cleanBody}
            references={references}
            responsibles={responsibles}
            entities={entities}
            accountId={selectedAccountId}
            onClose={closeModal}
            openEditModal={openEditModal}
            onSave={() => {
              closeModal();
              loadProperties(currentPage);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Properties;


const CreatePropertyModal = ({ cleanBody, references, responsibles, entities, onClose, onSave }) => {
  const [userData, setUserData] = useState(null);
  const profilePhotoInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [propertyData, setPropertyData] = useState({
    account: {
      email: '',
      password: '',
      confirmPassword: '',
      entity: '',
      responsibles: [],
      permission: 'producer',
    },
    property: {
      alias: '',
      address: '',
      addressNumber: '',
      addressComplement: '',
      city: '',
      state: '',
      postalCode: '',
      cafNumber: '',
      carNumber: '',
      legalArea: '',
      totalArea: '',
      appArea: '',
      coordinatesLat: '',
      coordinatesLng: '',
      productiveSystems: [],
    },
    users: []
  });
  const [error, setError] = useState('');

  useEffect(() => {
    console.log('propertyData', propertyData);
    setIsLoading(false);
  }, [propertyData]);


  const handleSave = async () => {
    try {
      const accountBody = cleanBody({
        email: propertyData.account.email,
        password: propertyData.account.password,
        confirmPassword: propertyData.account.confirmPassword,
        permission: 'producer',
        entity: propertyData.account.entity,
      });

      const propertyBody = cleanBody({
        alias: propertyData.property.alias,
        address: propertyData.property.address,
        addressNumber: propertyData.property.addressNumber,
        addressComplement: propertyData.property.addressComplement,
        city: propertyData.property.city,
        state: propertyData.property.state,
        postalCode: propertyData.property.postalCode,
        cafNumber: propertyData.property.cafNumber,
        carNumber: propertyData.property.carNumber,
        legalArea: propertyData.property.legalArea,
        totalArea: propertyData.property.totalArea,
        appArea: propertyData.property.appArea,
        coordinates: [
          parseFloat(propertyData.property.coordinatesLat),
          parseFloat(propertyData.property.coordinatesLng)
        ],
        productiveSystems: propertyData.property.productiveSystems,
      });

      const unifiedBody = {
        account: accountBody,
        property: propertyBody,
        user: (() => {
          const { id, ...userWithoutId } = propertyData.users[0]?.user || {};
          return userWithoutId;
        })(),
        profile: propertyData.users[0]?.profile || {},
        familyMembers: propertyData.users.slice(1).map(familyMember => {
          const { id, ...userWithoutId } = familyMember.user;
          return {
            user: userWithoutId,
            profile: familyMember.profile,
          };
        }),
        responsibles: propertyData.account.responsibles.map(responsible => responsible.id),
      };

      console.log('vai publicar:', unifiedBody);
      const result = await postAccount(unifiedBody);

      if (result) {
        onSave();
      } else {
        setError('Erro ao criar propriedade');
      }
    } catch (error) {
      console.error('Erro ao criar propriedade:', error);
      setError('Erro ao criar propriedade');
    }
  };

  const navigateToUserProfile = async (userId) => {
    setIsLoading(true);
    try {
        const user = propertyData.users.find(member => member.user.id === userId);
        
        if (user) {
            setUserData({
                user: user.user || {},
                profile: {
                    ...user.profile,
                    birthdate: user.profile?.birthdate ? formatDateToCompact(user.profile.birthdate) : '',
                },
            });
        } else {
            console.error('Usuário não encontrado');
            setError('Usuário não encontrado');
        }
    } catch (error) {
      console.error('Erro ao buscar detalhes do usuário:', error);
      setError('Erro ao buscar detalhes do usuário');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNewMember = () => {
    const isBeneficiary = propertyData.users.length === 0;

    const newMember = {
      id: null,
      name: '',
      nickname: '',
      role: isBeneficiary ? "beneficiary" : "familyMember",
      kinship: '',
      profilePhoto: null,
      cpf: '',
      birthdate: '',
      gender: '',
      maritalStatus: '',
      nationality: '',
      naturalness: '',
      scholarity: '',
      familyMembersNo: '',
    };
    
    setUserData({
      user: newMember,
      profile: newMember,
      references: references || {}
    });
  };

  const handleBackToPropertyView = () => {
    setUserData(null);
    setError('');
  };

   

  const handleSaveUser = async () => {
    try {
      setIsLoading(true);
        const profileBody = cleanBody({
            cpf: userData.profile.cpf,
            gender: userData.profile.gender,
            maritalStatus: userData.profile.maritalStatus,
            nationality: userData.profile.nationality,
            naturalness: userData.profile.naturalness,
            birthdate: userData.profile?.birthdate ? formatDateToISO8601(userData.profile.birthdate) : '',
            scholarity: userData.profile.scholarity,
            familyMembersNo: userData.profile.familyMembersNo,
            kinship: userData.profile.kinship,
        });

        const userBody = cleanBody({
            id: userData.user.id,
            name: userData.user.name,
            nickname: userData.user.nickname,
            contactNumber: userData.user.contactNumber,
            role: userData.user.role,
        });

        setPropertyData(prevState => ({
          ...prevState,
          users: prevState.users.map(member =>
            member.user.id === userData.user.id
              ? { user: userBody, profile: profileBody }
              : member
          ),
        }));

    } catch (error) {
        console.error('Erro ao salvar usuário:', error);
        setError('Erro ao salvar usuário');
    } finally {
      setUserData(null);
    }
  };

  const handleCreateUser = async () => {
    try {
        setIsLoading(true);

        const isBeneficiary = propertyData.users.length === 0;
        const tempId = `temp-${Date.now()}`;

        const profileBody = cleanBody({
            cpf: userData.profile.cpf,
            gender: userData.profile.gender,
            maritalStatus: userData.profile.maritalStatus,
            nationality: userData.profile.nationality,
            naturalness: userData.profile.naturalness,
            birthdate: userData.profile?.birthdate ? formatDateToISO8601(userData.profile.birthdate) : '',
            scholarity: userData.profile.scholarity,
            familyMembersNo: userData.profile.familyMembersNo,
            kinship: userData.profile.kinship,
        });

        const userBody = cleanBody({
            id: tempId,
            name: userData.user.name,
            nickname: userData.user.nickname,
            contactNumber: userData.user.contactNumber,
            role: isBeneficiary ? "beneficiary" : "familyMember",
          });

          setPropertyData(prevState => ({
              ...prevState,
              users: [
                  ...prevState.users,
                  { user: userBody, profile: profileBody }
              ]
          }));
    } catch (error) {
        console.error('Erro ao criar usuário:', error);
        setError('Erro ao criar usuário');
    } finally {
      setUserData(null);
    }
  };

  const formatDateToISO8601 = (dateString) => {
    const day = dateString.slice(0, 2);
    const month = dateString.slice(2, 4);
    const year = dateString.slice(4, 8);
    return new Date(`${year}-${month}-${day}T00:00:00.000Z`).toISOString();
  };

  const formatDateToCompact = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}${month}${year}`;
  };

  return (
    <Modal
      title="Cadastrar Propriedade"
      onClose={onClose}
      saveLabel={userData ? "Salvar usuário" : "Criar propriedade"}
      onSave={userData ? userData.user.id ? handleSaveUser : handleCreateUser : handleSave}
      onCancel={userData ? handleBackToPropertyView : onClose}
      cancelLabel={userData ? "Voltar" : "Cancelar"}
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <span>Carregando...</span>
        </div>
      ) : userData ? (
        <FamilyMemberForm
          memberData={userData}
          references={references}
          setMemberData={setUserData}
          familyImages={propertyData.familyImages}
          error={error}
          profilePhotoInputRef={profilePhotoInputRef}
          createMode
        />
      ) : (
        <div className="flex flex-col gap-xl">
          <form className="flex flex-col gap-xl">
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Beneficiário e Família</Body>
              <div className="flex flex-wrap gap-md">
                {(propertyData.users || []).map(member => (
                  <div
                    key={member.user.id}
                    className="flex flex-col items-center cursor-pointer gap-xs hover:opacity-50"
                    onClick={() => navigateToUserProfile(member.user.id)}
                  >
                    <div className="w-16 h-16 rounded-full bg-gray-1"></div>
                    <div className="flex items-center gap-xxxs">
                      {member.user.role === 'beneficiary' && (
                        <MaterialSymbol
                          icon='star'
                          size={16}
                          color='#799537'
                        />
                      )}
                      <Label size="md" style="text-green-3 text-center h-[12.8px]">
                        {member.user.nickname ? member.user.nickname : member.user.name}
                      </Label>
                    </div>
                    <Body size="sm" style="text-green-3 text-center">{member.kinship}</Body>
                  </div>
                ))}
                <div
                  className="flex flex-col items-center cursor-pointer gap-xs hover:opacity-50"
                  onClick={handleAddNewMember}
                >
                  <div className="flex items-center justify-center w-16 h-16 rounded-full bg-gray-1">
                    <MaterialSymbol
                      icon="add"
                      size={24}
                      color='#799537'
                    />
                  </div>
                  <Label size="md" style="text-green-3 text-center">
                    {propertyData.users?.length === 0 ? "Adicionar Beneficiário" : "Adicionar Familiar"}
                  </Label>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Conta</Body>
              <InputSelect
                label="Entidade"
                options={entities}
                value={propertyData.account.entity}
                icon='domain'
                onChange={value => setPropertyData(prevState => ({ ...prevState, account: { ...prevState.account, entity: value } }))}
                disabled={!!propertyData.account.id}
              />
              <InputSelect
                label="Responsáveis"
                options={responsibles}
                value={propertyData.account.responsibles}
                icon='group'
                multiselect
                onChange={value => {
                  setPropertyData(prevState => ({ 
                    ...prevState, 
                    account: { 
                      ...prevState.account, 
                      responsibles: value  // Apenas atualiza com o array de IDs selecionados
                    } 
                  }));
                }}
              />
              <InputText
                name="email"
                label="E-mail"
                value={propertyData.account.email}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, account: { ...prevState.account, email: value } }))}
                icon='email'
              />
              <InputPassword
                name="password"
                label="Senha"
                value={propertyData.account.password}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, account: { ...prevState.account, password: value } }))}
                icon="password"
                autoComplete='new-password'
              />
              <InputPassword
                name="confirmPassword"
                label="Confirmação de senha"
                value={propertyData.account.confirmPassword}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, account: { ...prevState.account, confirmPassword: value } }))}
                icon="password"
              />
            </div>
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Detalhes da Propriedade</Body>
              <InputText
                name="alias"
                label="Nome da Propriedade"
                value={propertyData.property.alias}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, alias: value } }))}
                icon='short_text'
              />
              <InputNumber
                name="totalArea"
                label="Área Total (ha)"
                value={propertyData.property.totalArea}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, totalArea: value } }))}
                icon='pageless'
              />
              <InputNumber
                name="legalArea"
                label="Área Legal (ha)"
                value={propertyData.property.legalArea}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, legalArea: value } }))}
                icon='area_chart'
              />
              <InputNumber
                name="appArea"
                label="Área APP (ha)"
                value={propertyData.property.appArea}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, appArea: value } }))}
                icon='nature'
              />
              <InputText
                name="carNumber"
                label="Nº CAR"
                value={propertyData.property.carNumber}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, carNumber: value } }))}
                icon='short_text'
              />
              <InputText
                name="cafNumber"
                label="Nº CAF"
                value={propertyData.property.cafNumber}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, cafNumber: value } }))}
                icon='short_text'
              />
            </div>
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Atividades produtivas</Body>
              <InputSelect
                label="Atividades produtivas"
                options={references?.productiveSystems || []}
                value={propertyData.property?.productiveSystems || []}
                icon='short_text'
                multiselect
                onChange={value => setPropertyData(prevState => ({
                  ...prevState,
                  property: {
                    ...prevState.property,
                    productiveSystems: value
                  }
                }))}
              />
            </div>
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Endereço</Body>
              <InputText
                name="address"
                label="Endereço"
                value={propertyData.property.address}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, address: value } }))}
                icon='short_text'
              />
              <div className="flex gap-xs">
                <InputNumber
                  name="addressNumber"
                  label="Número"
                  value={propertyData.property.addressNumber}
                  onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, addressNumber: value } }))}
                  icon='short_text'
                />
                <InputText
                  name="addressComplement"
                  label="Complemento"
                  value={propertyData.property.addressComplement}
                  onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, addressComplement: value } }))}
                  icon='short_text'
                />
              </div>
              <InputNumber
                name="postalCode"
                label="CEP"
                value={propertyData.property.postalCode}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, postalCode: value } }))}
                icon='short_text'
                mask='99999-999'
                sanitizeValue={value => value.replace(/[^0-9]/g, '')}
              />
              <InputText
                name="city"
                label="Cidade"
                value={propertyData.property.city}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, city: value } }))}
                icon='short_text'
              />
              <InputSelect
                label="Estado"
                options={references?.ufs || []}
                value={propertyData.property.state}
                icon='short_text'
                onChange={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, state: value } }))}
              />
              <div className="flex gap-xs">
                <InputNumber
                  name="coordinatesLat"
                  label="Latitude"
                  value={propertyData.property.coordinatesLat}
                  onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, coordinatesLat: value } }))}
                  icon='short_text'
                />
                <InputNumber
                  name="coordinatesLng"
                  label="Longitude"
                  value={propertyData.property.coordinatesLng}
                  onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, coordinatesLng: value } }))}
                  icon='short_text'
                />
              </div>

            </div>
          </form>
          {error && <FormError text={error} />}
        </div>
      )}
    </Modal>
  );
};

export const EditPropertyModal = ({ cleanBody, references, responsibles, entities, accountId, onClose, openEditModal, onSave }) => {
  const [propertyData, setPropertyData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [coverPhotoUrl, setCoverPhotoUrl] = useState('');
  const coverPhotoInputRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const profilePhotoInputRef = useRef(null);

  const loadPropertyData = async () => {
    setIsLoading(true);
    setPropertyData(null);
    try {
      const data = await getAccount(accountId);
      const familyImagesPromises = data.users.map(async (member) => {
        if (member.profilePhoto) {
          const url = await fetchProfilePhoto(member.profilePhoto);
          return { [member.id]: url };
        }
        return { [member.id]: null };
      });

      const resolvedFamilyImages = await Promise.all(familyImagesPromises);
      const imagesMap = resolvedFamilyImages.reduce((acc, curr) => ({ ...acc, ...curr }), {});

      const [coordinatesLat, coordinatesLng] = data.property.coordinates
      ? data.property.coordinates.replace(/[()]/g, '').split(',').map(coord => coord.trim())
      : [null, null];

      setPropertyData({
        ...data,
        property: {
          ...data.property,
          
          coordinatesLat: coordinatesLat || '',
          coordinatesLng: coordinatesLng || '',
        },
        familyImages: imagesMap,
      });

      if (data.property.coverPhoto) {
        const url = await fetchCoverPhoto(data.property.coverPhoto);
        setCoverPhotoUrl(url);
      }

    } catch (error) {
      console.error('Erro ao carregar propriedade:', error);
      setError('Erro ao carregar propriedade');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadPropertyData();
  }, [accountId]);

  const formatDateToISO8601 = (dateString) => {
    const day = dateString.slice(0, 2);
    const month = dateString.slice(2, 4);
    const year = dateString.slice(4, 8);
    return new Date(`${year}-${month}-${day}T00:00:00.000Z`).toISOString();
  };

  const formatDateToCompact = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const year = date.getUTCFullYear();
    return `${day}${month}${year}`;
  };

  const handleSave = async () => {
    try {
      const accountBody = cleanBody({
        email: propertyData.account.email,
        password: propertyData.account.password,
        confirmPassword: propertyData.account.confirmPassword,
        permission: 'producer',
      });

      let coordinates = null;
      if (propertyData.property.coordinatesLat && propertyData.property.coordinatesLng) {
          coordinates = [
              parseFloat(propertyData.property.coordinatesLat), 
              parseFloat(propertyData.property.coordinatesLng)
          ];
      }

      const propertyBody = cleanBody({
        alias: propertyData.property.alias,
        address: propertyData.property.address,
        addressNumber: propertyData.property.addressNumber,
        addressComplement: propertyData.property.addressComplement,
        city: propertyData.property.city,
        state: propertyData.property.state,
        postalCode: propertyData.property.postalCode,
        cafNumber: propertyData.property.cafNumber,
        carNumber: propertyData.property.carNumber,
        legalArea: propertyData.property.legalArea,
        totalArea: propertyData.property.totalArea,
        appArea: propertyData.property.appArea,
        coordinates: coordinates,
        productiveSystems: propertyData.property.productiveSystems,
      });

      const result = await putUser(accountId, null, { account: accountBody, property: propertyBody }, null);

      if (result) {
        onSave();
      } else {
        setError('Erro ao atualizar propriedade');
      }
    } catch (error) {
      console.error('Erro ao salvar propriedade:', error);
      setError('Erro ao salvar propriedade');
    }
  };

  const handleSaveUser = async () => {
    try {
      setIsLoading(true);
        const profileBody = cleanBody({
            cpf: userData.profile.cpf,
            gender: userData.profile.gender,
            maritalStatus: userData.profile.maritalStatus,
            nationality: userData.profile.nationality,
            naturalness: userData.profile.naturalness,
            birthdate: userData.profile?.birthdate ? formatDateToISO8601(userData.profile.birthdate) : '',
            scholarity: userData.profile.scholarity,
            familyMembersNo: userData.profile.familyMembersNo,
            kinship: userData.profile.kinship,
        });

        const userBody = cleanBody({
            name: userData.user.name,
            nickname: userData.user.nickname,
            contactNumber: userData.user.contactNumber,
            role: userData.user.role,
        });

        const profilePhotoFile = profilePhotoInputRef.current?.files[0];

        if (profilePhotoFile) {
            await putUserProfilePhoto(userData.user.id, profilePhotoInputRef.current);
        }

        const result = await putUser(propertyData.account.id, userData.user.id, null, { user: userBody, profile: profileBody });

        if (result) {
          loadPropertyData();
        } else {
            setError('Erro ao salvar usuário');
        }
    } catch (error) {
        console.error('Erro ao salvar usuário:', error);
        setError('Erro ao salvar usuário');
    } finally {
      setUserData(null);
    }
  };

  const handleCreateUser = async () => {
    try {
        setIsLoading(true);
        console.log('Iniciando criação de usuário');

        const profileBody = cleanBody({
            cpf: userData.profile.cpf,
            gender: userData.profile.gender,
            maritalStatus: userData.profile.maritalStatus,
            nationality: userData.profile.nationality,
            naturalness: userData.profile.naturalness,
            birthdate: userData.profile?.birthdate ? formatDateToISO8601(userData.profile.birthdate) : '',
            scholarity: userData.profile.scholarity,
            kinship: userData.profile.kinship,
        });

        const userBody = cleanBody({
            name: userData.user.name,
            nickname: userData.user.nickname,
            role: 'familyMember',
        });

        // Armazenando a imagem localmente para garantir que ela esteja disponível para o upload
        const profilePhotoFile = profilePhotoInputRef.current?.files?.[0];
        console.log('Verificando presença de imagem de perfil:', profilePhotoFile ? 'Imagem presente' : 'Imagem não encontrada');

        const result = await postUser(propertyData.account.id, { user: userBody, profile: profileBody });
        console.log('Resultado da criação de usuário:', result);

        if (result && result.responseStatus === 'ok' && result.data?.[0]?.id) {
            const userId = result.data[0].id;
            console.log('Usuário criado com sucesso:', userId);

            // Verifique se a referência ao arquivo ainda é válida
            if (profilePhotoFile) {
                console.log('Enviando foto do perfil para o usuário:', userId);
                // Envie o arquivo diretamente, sem depender de `profilePhotoInputRef` novamente
                await putUserProfilePhoto(userId, profilePhotoFile);
                console.log('Foto do perfil enviada com sucesso');
            } else {
                console.log('Nenhuma foto do perfil para enviar');
            }

            loadPropertyData();
        } else {
            setError('Erro ao salvar usuário');
            console.error('Erro na resposta do servidor ao tentar criar o usuário');
        }
    } catch (error) {
        console.error('Erro ao salvar usuário:', error);
        setError('Erro ao salvar usuário');
    } finally {
        setUserData(null);
        setIsLoading(false);
        console.log('Finalizando criação de usuário');
    }
  };
  
  const navigateToUserProfile = async (userId) => {
    setIsLoading(true);
    try {
      const data = await getUser(userId);
  
      if (data) {
        setUserData({
          user: data.user?.user || {},
          profile: {
            ...data.user?.profile,
            birthdate: data.user?.profile?.birthdate ? formatDateToCompact(data.user.profile.birthdate) : '',
          } || {},
          references: data.references || {},
        });
      }
    } catch (error) {
      console.error('Erro ao buscar detalhes do usuário:', error);
      setError('Erro ao buscar detalhes do usuário');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNewMember = () => {
    const newMember = {
      id: null,
      name: '',
      nickname: '',
      role: 'familyMember',
      kinship: '',
      profilePhoto: null,
      cpf: '',
      birthdate: '',
      gender: '',
      maritalStatus: '',
      nationality: '',
      naturalness: '',
      scholarity: '',
      familyMembersNo: '',
    };
    
    setUserData({
      user: newMember,
      profile: newMember,
      references: references || {}
    });
  };

  const handleBackToPropertyView = () => {
    const originalUserData = propertyData.users.find(user => user.id === userData.id);
    setUserData(originalUserData);
    setUserData(null);
    setError('');
  };

  return (
    <Modal
      title="Editar Propriedade"
      onClose={onClose}
      saveLabel="Salvar"
      onSave={userData ? userData.user.id ? handleSaveUser : handleCreateUser : handleSave}
      saveLabel={userData ? "Salvar usuário" : "Salvar propriedade"}
      onCancel={userData ? handleBackToPropertyView : onClose}
      cancelLabel={userData ? "Voltar" : "Cancelar"}
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <span>Carregando...</span>
        </div>
      ) : userData ? (
        <FamilyMemberForm
          memberData={userData}
          references={references}
          setMemberData={setUserData}
          familyImages={propertyData.familyImages}
          error={error}
          profilePhotoInputRef={profilePhotoInputRef}
        />
      ) : (
        <div className="flex flex-col gap-xl">
          <form className="flex flex-col gap-xl">
            {propertyData.account.id && (
              <div className="flex flex-col gap-xs">
                <InputCoverPhoto imageSrc={coverPhotoUrl} ref={coverPhotoInputRef} />
              </div>
            )}
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Beneficiário e Família</Body>
              <div className="flex flex-wrap gap-md">
                {(propertyData.users || []).map(member => (
                  <div
                    key={member.id}
                    className="flex flex-col items-center cursor-pointer gap-xs hover:opacity-50"
                    onClick={() => navigateToUserProfile(member.id)}
                  >
                    {propertyData.familyImages[member.id] ? (
                      <img
                        src={propertyData.familyImages[member.id]} 
                        alt={member.name}
                        className="w-16 h-16 rounded-full bg-gray-1 object-cover"
                      />
                    ) : (
                      <div className="w-16 h-16 rounded-full bg-gray-1"></div>
                    )}

                    <div className="flex items-center gap-xxxs">
                      {member.role === 'beneficiary' && (
                        <MaterialSymbol
                          icon='star'
                          size={16}
                          color='#799537'
                        />
                      )}
                      <Label size="md" style="text-green-3 text-center h-[12.8px]">
                        {member.nickname ? member.nickname : member.name}
                      </Label>
                    </div>
                    <Body size="sm" style="text-green-3 text-center">{member.kinship}</Body>
                  </div>
                ))}
                <div
                  className="flex flex-col items-center cursor-pointer gap-xs hover:opacity-50"
                  onClick={handleAddNewMember}
                >
                  <div className="flex items-center justify-center w-16 h-16 rounded-full bg-gray-1">
                    <MaterialSymbol
                      icon="add"
                      size={24}
                      color='#799537'
                    />
                  </div>
                  <Label size="md" style="text-green-3 text-center">
                    {propertyData.users?.length === 0 ? "Adicionar Beneficiário" : "Adicionar Familiar"}
                  </Label>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Conta</Body>
              <InputSelect
                label="Entidade"
                options={entities}
                value={propertyData.account.entity}
                icon='domain'
                onChange={value => setPropertyData(prevState => ({ ...prevState, account: { ...prevState.account, entity: value } }))}
                disabled={!!propertyData.account.id}
              />
              <InputSelect
                label="Responsáveis"
                options={responsibles}
                value={propertyData.account.responsibles}
                icon='group'
                multiselect
                onChange={value => {
                  setPropertyData(prevState => ({ 
                    ...prevState, 
                    account: { 
                      ...prevState.account, 
                      responsibles: value  // Apenas atualiza com o array de IDs selecionados
                    } 
                  }));
                }}
              />
              <InputText
                name="email"
                label="E-mail"
                value={propertyData.account.email}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, account: { ...prevState.account, email: value } }))}
                icon='email'
              />
              <InputPassword
                name="password"
                label="Senha"
                value={propertyData.account.password}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, account: { ...prevState.account, password: value } }))}
                icon="password"
                autoComplete='new-password'
              />
              <InputPassword
                name="confirmPassword"
                label="Confirmação de senha"
                value={propertyData.account.confirmPassword}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, account: { ...prevState.account, confirmPassword: value } }))}
                icon="password"
              />
            </div>
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Detalhes da Propriedade</Body>
              <InputText
                name="alias"
                label="Nome da Propriedade"
                value={propertyData.property.alias}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, alias: value } }))}
                icon='short_text'
              />
              <InputNumber
                name="totalArea"
                label="Área Total (ha)"
                value={propertyData.property.totalArea}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, totalArea: value } }))}
                icon='pageless'
              />
              <InputNumber
                name="legalArea"
                label="Área Legal (ha)"
                value={propertyData.property.legalArea}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, legalArea: value } }))}
                icon='area_chart'
              />
              <InputNumber
                name="appArea"
                label="Área APP (ha)"
                value={propertyData.property.appArea}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, appArea: value } }))}
                icon='nature'
              />
              <InputText
                name="carNumber"
                label="Nº CAR"
                value={propertyData.property.carNumber}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, carNumber: value } }))}
                icon='short_text'
              />
              <InputText
                name="cafNumber"
                label="Nº CAF"
                value={propertyData.property.cafNumber}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, cafNumber: value } }))}
                icon='short_text'
              />
            </div>
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Atividades produtivas</Body>
              <InputSelect
                label="Atividades produtivas"
                options={references?.productiveSystems || []}
                value={propertyData.property?.productiveSystems || []}
                icon='short_text'
                multiselect
                onChange={value => setPropertyData(prevState => ({
                  ...prevState,
                  property: {
                    ...prevState.property,
                    productiveSystems: value
                  }
                }))}
              />
            </div>
            <div className="flex flex-col gap-xs">
              <Body size='lg' style="text-green-3">Endereço</Body>
              <InputText
                name="address"
                label="Endereço"
                value={propertyData.property.address}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, address: value } }))}
                icon='short_text'
              />
              <div className="flex gap-xs">
                <InputNumber
                  name="addressNumber"
                  label="Número"
                  value={propertyData.property.addressNumber}
                  onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, addressNumber: value } }))}
                  icon='short_text'
                />
                <InputText
                  name="addressComplement"
                  label="Complemento"
                  value={propertyData.property.addressComplement}
                  onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, addressComplement: value } }))}
                  icon='short_text'
                />
              </div>
              <InputNumber
                name="postalCode"
                label="CEP"
                value={propertyData.property.postalCode}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, postalCode: value } }))}
                icon='short_text'
                mask='99999-999'
                sanitizeValue={value => value.replace(/[^0-9]/g, '')}
              />
              <InputText
                name="city"
                label="Cidade"
                value={propertyData.property.city}
                onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, city: value } }))}
                icon='short_text'
              />
              <InputSelect
                label="Estado"
                options={references?.ufs || []}
                value={propertyData.property.state}
                icon='short_text'
                onChange={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, state: value } }))}
              />
              <div className="flex gap-xs">
                <InputNumber
                  name="coordinatesLat"
                  label="Latitude"
                  value={propertyData.property.coordinatesLat}
                  onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, coordinatesLat: value } }))}
                  icon='short_text'
                />
                <InputNumber
                  name="coordinatesLng"
                  label="Longitude"
                  value={propertyData.property.coordinatesLng}
                  onChangeText={value => setPropertyData(prevState => ({ ...prevState, property: { ...prevState.property, coordinatesLng: value } }))}
                  icon='short_text'
                />
              </div>

            </div>
          </form>
          {error && <FormError text={error} />}
        </div>
      )}
    </Modal>
  );
};


const FamilyMemberForm = ({ memberData, references, setMemberData, familyImages, error, profilePhotoInputRef, createMode = false }) => {
  return (
    <div className="flex flex-col gap-xl">
      {!createMode && (
        <div className="flex flex-col items-center">
          <InputProfilePhoto
            imageSrc={memberData?.user?.id ? familyImages[memberData.user.id] : undefined}
            ref={profilePhotoInputRef}
          />
        </div>
      )}
      {memberData.user.role && !memberData.user.role.includes('beneficiary') && (
        <div className="flex flex-col gap-xs">
          <Body size='lg' style="text-green-3">Parentesco</Body>
          <InputSelect
            label="Parentesco"
            options={references.kinships}
            value={memberData.profile.kinship}
            icon='family_restroom'
            onChange={value => setMemberData(prevState => ({
              ...prevState,
              profile: {
                ...prevState.profile,
                kinship: value
              }
            }))}
          />
        </div>
      )}

      <div className="flex flex-col gap-xs">
        <Body size='lg' style="text-green-3">Informações pessoais</Body>
        <InputText
          label="Nome"
          value={memberData.user.name}
          icon='person'
          onChangeText={text => setMemberData(prevState => ({
            ...prevState,
            user: {
              ...prevState.user,
              name: text
            }
          }))}
        />
        <InputText
          label="Apelido"
          value={memberData.user.nickname}
          icon='tag_faces'
          onChangeText={text => setMemberData(prevState => ({
            ...prevState,
            user: {
              ...prevState.user,
              nickname: text
            }
          }))}
        />
        <InputNumber
          label="CPF"
          value={memberData.profile.cpf}
          icon='short_text'
          mask='999.999.999-99'
          onChangeText={text => setMemberData(prevState => ({
            ...prevState,
            profile: {
              ...prevState.profile,
              cpf: text
            }
          }))}
          sanitizeValue={value => value.replace(/[^0-9]/g, '')}
        />
        <InputNumber
          label="Data de nascimento"
          value={memberData.profile.birthdate}
          icon='calendar_today'
          mask='99/99/9999'
          onChangeText={text => setMemberData(prevState => ({
            ...prevState,
            profile: {
              ...prevState.profile,
              birthdate: text
            }
          }))}
        />
        <InputSelect
          label="Gênero"
          options={references.genders}
          value={memberData.profile.gender}
          icon='face'
          onChange={value => setMemberData(prevState => ({
            ...prevState,
            profile: {
              ...prevState.profile,
              gender: value
            }
          }))}
        />
        <InputSelect
          label="Estado civil"
          options={references.maritalStatuses}
          value={memberData.profile.maritalStatus}
          icon='group'
          onChange={value => setMemberData(prevState => ({
            ...prevState,
            profile: {
              ...prevState.profile,
              maritalStatus: value
            }
          }))}
        />
        <InputText
          label="Nacionalidade"
          value={memberData.profile.nationality}
          icon='flag'
          onChangeText={text => setMemberData(prevState => ({
            ...prevState,
            profile: {
              ...prevState.profile,
              nationality: text
            }
          }))}
        />
        <InputText
          label="Naturalidade"
          value={memberData.profile.naturalness}
          icon='person_pin_circle'
          onChangeText={text => setMemberData(prevState => ({
            ...prevState,
            profile: {
              ...prevState.profile,
              naturalness: text
            }
          }))}
        />
        <InputSelect
          label="Escolaridade"
          options={references.scholarities}
          value={memberData.profile.scholarity}
          icon='school'
          onChange={value => setMemberData(prevState => ({
            ...prevState,
            profile: {
              ...prevState.profile,
              scholarity: value
            }
          }))}
        />
        {memberData.user.role && memberData.user.role.includes('beneficiary') && (
          <>
            <InputNumber
              label="Contato"
              value={memberData.user.contactNumber}
              icon='phone'
              mask='(99) 99999-9999'
              onChangeText={text => setMemberData(prevState => ({
                ...prevState,
                user: {
                  ...prevState.user,
                  contactNumber: text
                }
              }))}
              sanitizeValue={value => value.replace(/[^0-9]/g, '')}
            />
            <InputNumber
              label="Quantidade de familiares"
              value={memberData.profile.familyMembersNo}
              icon='family_restroom'
              onChangeText={text => setMemberData(prevState => ({
                ...prevState,
                profile: {
                  ...prevState.profile,
                  familyMembersNo: text
                }
              }))}
            />
          </>
        )}
      </div>
      {error && <FormError text={error} />}
    </div>
  );
};
