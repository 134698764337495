import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function WYSIWYG ({value, onChange}) {
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered'}, { list: 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      ['link', 'image'],
      [{ 'align': [] }],
    ],
  };

  return (
    <div>
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={modules}
      />
    </div>
  );
}

export default WYSIWYG;