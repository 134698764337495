import { useState, useEffect, useRef } from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import mime from 'mime'

import AudioPlayer from './AudioPlayer'
import ImagePreview from './ImagePreview'
import VideoPreview from './VideoPreview'
import Label from '../../typography/Label'
import { fetchMessageAttachment } from '../../../api/chats'

const Media = ({item}) => {
  const [errorOnLoad, setErrorOnLoad] = useState(false)
  const [media, setMedia] = useState()
  
  const imageRef = useRef(null)
  const audioRef = useRef(null)
  const videoRef = useRef(null)
  
  const getMediaType = (media, extension) => {
    if (!media) return 'unknown'
    
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'webp':
        return 'image'
      case 'mp4':
      case 'mov':
      case 'avi':
        return 'video'
      case '3gpp':
      case '3gpp2':
      case 'mp3':
      case 'mpga':
        return 'audio'
      default:
        return 'unknown'
    }
  }

  const extension = item.message.media.split('.').pop().toLowerCase()
  const mediaType = getMediaType(item.message.media, extension)

  useEffect(() => {
    if (mediaType !== 'unknown') {
      fetchMessageAttachment(item.message.media)
        .then(mediaUrl => {
          setMedia(mediaUrl)
        })
        .catch(_ => {
          setErrorOnLoad(true)
        })
    }
  }, [item])

  useEffect(() => {
    if (mediaType === 'image' && imageRef.current) {
      imageRef.current.src = media
    }

    if (mediaType === 'video' && videoRef.current) {
      videoRef.current.src = media
      videoRef.current.type = mime.getType(extension)
    }

    if (mediaType === 'audio' && audioRef.current) { 
      audioRef.current.src = media
    }
  }, [media])

  return (
    <div>
      {mediaType === 'image' && (
        <ImagePreview item={item} imageRef={imageRef} errorOnLoad={errorOnLoad}/>
      )}
      {mediaType === 'video' && (  
        <VideoPreview item={item} videoRef={videoRef} errorOnLoad={errorOnLoad} />
      )}
      {mediaType === 'audio' && item && (
        <AudioPlayer item={item} audioRef={audioRef} errorOnLoad={errorOnLoad} audioUrl={media} />
      )}
      {mediaType === 'unknown' && (
        <button
          onClick={() => handleOpenFile(item.mediaUri)}
          className={`flex flex-row items-center p-sm rounded-xs ${item.type === 'messageReceived' ? 'bg-white-1' : 'bg-green-2'}`}
          style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}
        >
          <MaterialSymbol
            icon='insert_drive_file'
            color='#B27C1F'
            size={24}
          />
          <Label size='md' style='text-yellow-3'>Abrir Arquivo</Label>
        </button>
      )}
    </div>
  )
}

export default Media
