import { useState, useEffect, createContext, useContext } from 'react'

import ChatList from '../../components/fragments/chat/ChatList'
import ChatScreen from '../../components/fragments/chat/ChatScreen'
import { EditPropertyModal } from './Properties'
import { getReferences, getUsers } from '../../api/users'
import { getSessionData } from '../../api/auth'
import { cleanBody } from '../../api/_helpers'

const ChatContext = createContext()
export const useChatContext = () => useContext(ChatContext)

const sessionData = getSessionData()
const account = sessionData?.account
const currentUser = sessionData?.users[0]

const references = await getReferences()
  .catch(e => { console.error(e.message) })

const responsibles = await getUsers({ role: 'technician', limit: 50 })
  .then(response =>  response.data.map(tech => ({ id: tech.email, value: tech.email })))
  .catch(e => { console.error(e.message) })

const entities = [
  { value: 'IPAM', label: 'IPAM' },
  { value: 'FVPP', label: 'FVPP' }
]

const Chats = () => {
  const [error, setError] = useState('')
  const [chatList, setChatList] = useState([])
  const [chat, setChat] = useState({})
  const [shouldRefreshChat, setShouldRefreshChat] = useState(false)
  const [editingProperty, setEditingProperty] = useState(false)
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false)
  const [isCameraActive, setIsCameraActive] = useState(false)
  const [isOptionsMenuVisible, setIsOptionsMenuVisible] = useState(false)
  const [profilePhotoMap, setProfilePhotoMap] = useState({})
  const profilePhotoMapSyncControl = new Set()

  useEffect(() => {
    if (error) {
      console.error('Erro: ', error)
      setError()
    } 
  }, [error])

  return (
    <ChatContext.Provider value={{
      account, currentUser,
      error, setError,
      chatList, setChatList,
      chat, setChat,
      shouldRefreshChat, setShouldRefreshChat,
      editingProperty, setEditingProperty,
      profilePhotoMap, setProfilePhotoMap, profilePhotoMapSyncControl,
      isSearchBarVisible, setIsSearchBarVisible,
      isCameraActive, setIsCameraActive,
      isOptionsMenuVisible, setIsOptionsMenuVisible
    }}>
      <div className='flex flex-col w-full'>
          <div className='flex h-screen w-full'>
          {chat && references && editingProperty && (
              <EditPropertyModal
                accountId={chat.producer.account.id}
                references={references}
                responsibles={responsibles}
                entities={entities}
                cleanBody={cleanBody}
                onClose={() => { setEditingProperty(false) }}
                // TODO
                onSave={() => { console.log('Saving property') }}
              />
            )}
            <div className='flex flex-col gap-sm w-1/2'>
              <div className='flex w-full h-screen pt-lg'>
                <ChatList />
              </div>
            </div>
            <div className='flex flex-col h-screen gap-sm w-1/2'>
              <ChatScreen />
            </div>
          </div>
      </div>
    </ChatContext.Provider>
  )
}

export default Chats
