import { isToday, isYesterday, format } from 'date-fns'
import { MaterialSymbol } from 'react-material-symbols'

import { useChatContext } from '../../../pages/Protected/Chats'
import Body from '../../typography/Body'
import Label from '../../typography/Label'
import defaultProfilePhoto from '../../../assets/images/profile.jpg'

const Chat = ({ chat }) => {
  const { profilePhotoMap, setChat } = useChatContext()

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp)
  
    if (isToday(date)) {
      return format(date, 'HH:mm')
    } else if (isYesterday(date)) {
      return 'Ontem'
    } else {
      return format(date, 'dd/MM/yy')
    }
  }

  return (
    <div>
      <button
        className='flex flex-row items-center py-md px-sm gap-xs w-full'
        onClick={() => {
          setChat(chat)
        }}
      >
        {/* Image */}
        <div className={`h-xl w-xl rounded-full ${chat.servicePriorityLevel && chat.servicePriorityLevel !== 'indefinida' ? 'border-2 border-yellow-3' : ''}`}>
          <img
            src={profilePhotoMap[chat.producer.user.id] || defaultProfilePhoto}
            alt={`${chat.producer.user.nickname || chat.producer.user.name} profile`}
            className='h-full w-full bg-gray-1 rounded-full'
          />
        </div>
        {/* Basic info */}
        <div className='flex flex-col grow gap-xxs overflow-hidden'>
          {/* Name or Alias */}
          <div className='flex flex-row grow justify-between'>
            <div className='flex items-center gap-xxs'>
              {chat.servicePriorityLevel && chat.servicePriorityLevel !== 'indefinida' &&  (
                <div className='w-sm h-sm rounded-full bg-yellow-3 flex items-center justify-center'>
                  <MaterialSymbol
                    icon='priority_high'
                    color='#FBFCF8'
                    size={12}
                  />
                </div>
              )}
              <Label size='lg' style='text-green-4'>{chat.beneficiaryNickname || chat.beneficiaryName || chat.propertyAlias}</Label>
              {chat.serviceNumber && <Body size='md' style='text-gray-3'>#{chat.serviceNumber}</Body>}
            </div>
            {/* Timestamp */}
            <div className='items-center justify-center gap-xs'>
              {chat.messageTimestamp && <Body size='md' style='text-gray-3'>{formatTimestamp(chat.messageTimestamp)}</Body>}
            </div>
          </div>
          {/* Preview */}
          <div className='flex flex-row items-center gap-xxxs'>
            {chat.messageHasMedia && <MaterialSymbol icon='attach_file' color='#A3A799' size={12}/>} 
            <Body size='md' style='text-gray-3' numberOfLines={1} ellipsizeMode='tail'>{
              !chat.lastMessage ? 
                chat.messageHasMedia
                  ? 'Mídia' 
                  : 'Nenhuma mensagem'
                : chat.lastMessage
            }</Body>
          </div>
        </div>
      </button>
      <hr className='gray-1 w-full flex-1'/>
    </div>
  )
}

export default Chat