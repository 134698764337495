import { useState } from 'react'
import { format } from 'date-fns'
import { MaterialSymbol } from 'react-material-symbols'

import Body from '../../typography/Body'
import Label from '../../typography/Label'

const ImagePreview = ({ item, imageRef, errorOnLoad }) => {
  const [hoverImage, setHoverImage] = useState(false)

  return (
    <button
      className={`${item.content && item.content !== ' ' ? 'rounded-t-xs' : 'rounded-xs'} flex flex-row justify-center items-center w-60 h-60 bg-black-1 relative`}
      style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}
      onClick={() => handleOpenMediaViewer(item.mediaUri)}
      onMouseEnter={() => setHoverImage(!hoverImage)}
      onMouseLeave={() => setHoverImage(!hoverImage)}
    >
      {!errorOnLoad && (
        <img
          className={`${item.content && item.content !== ' ' ? 'rounded-t-xs' : 'rounded-xs'} max-h-60 max-w-60 object-contain`}
          ref={imageRef}
        />
      )}

      {errorOnLoad && (
        <div className='flex flex-col gap-xxxs items-center rounded-xs w-full h-full justify-center absolute'>
          <div 
            className={`flex items-center rounded-xs w-full h-full justify-center absolute bg-black-1 ${(hoverImage) ? 'opacity-0' : 'opacity-50'}`}
          />
          <MaterialSymbol
            icon='broken_image'
            color='#FBFCF8'
            size={32}
          />
          <Body style='text-white-1'>
            Erro ao carregar imagem
          </Body>
        </div>
      )}

      {!item.content && (
        <Label size='sm' style='text-white-1 self-end absolute bottom-xxs right-xs'>{format(new Date(item.createdAt), 'HH:mm')}</Label>
      )}
    </button>
  )
}

export default ImagePreview
