/** @type {string} */
let string

/** @type {string?} */
let optString

/** @type {number} */
let number

/** @type {number?} */
let optNumber

/** @type {boolean} */
let boolean

/** @type {boolean?} */
let optBoolean

/** @type {"true"|"false"} */
let stringBoolean

/** @type {"asc"|"desc"?} */
let optOrderDirections

/** @type {{ [key: string]: ({}|string)[] } | undefined} */
let referencesObject

/** @type {Array<{}>|[]|undefined} */
let datObject

/** @type {Array<number>|null} */
let referencesIdArray

export const QueryParams = {
  page: optNumber,
  limit: optNumber,
  paginate: optBoolean,
  orderBy: optString,
  orderDirection: optOrderDirections,
  getReferences: optBoolean,
  search: optString
}; Object.freeze(QueryParams)

export const GetServicesParams = {
  ...QueryParams
}; Object.freeze(GetServicesParams)

export const GetAppointmentsParams = { 
  ...QueryParams
}; Object.freeze(GetAppointmentsParams)

export const GetContentsParams = { 
  ...QueryParams
}; Object.freeze(GetContentsParams)

export const Content = {
  title: string,
  categories: referencesIdArray,
  types: referencesIdArray,
  excerpt: string,
  articleContent: optString,
  url: optString
}; Object.freeze(Content)

export const ContentBody = {
  content: { ...Content }
}; Object.freeze(ContentBody)

export const APIResponse = {
  links: {
    first: optString,
    self: string,
    prev: optString,
    next: optString,
    metaData: {
      currentPage: optNumber,
      lastPage: optNumber,
      total: number
    }
  },
  data: datObject,
  references: referencesObject
}; Object.freeze(APIResponse)
