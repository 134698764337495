import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { isSameDay, format } from 'date-fns';

import Display from '../../components/typography/Display';
import ButtonPrimary from '../../components/actions/ButtonPrimary';
import Body from '../../components/typography/Body';
import Table from '../../components/layout/Table';
import Modal from '../../components/overlay/Modal';
import ContentField from '../../components/fragments/ContentField';
import FormError from '../../components/inputs/FormError';
import InputSelect from '../../components/inputs/InputSelect';
import ChangeLog from '../../components/fragments/chat/ChangeLog';
import Message from '../../components/fragments/chat/Message';
import pattern from '../../assets/images/pattern.png'
import defaultProfilePhoto from '../../assets/images/profile.jpg'

import { getAppointmentReferences } from '../../api/appointments';
import { getServices, downloadServicesCSV, getServiceById, putService } from '../../api/chats';
import { Service } from '../../types';
import { fetchProfilePhoto } from '../../api/users';
import { getSessionData } from '../../api/auth';

const Services = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [references, setReferences] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [filter, setFilter] = useState('');
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const loadServices = async ({page, limit, search = searchTerm}) => {
    const result = await getServices({ 
      page,
      limit,
      search ,
      orderBy: 'serviceNumber',
      orderDirection: 'desc'
    });

    result.data = result.data.map(service => ({
      ...service,
      producerNameOrNickname: service.producer.user.nickname || service.producer.user.name,
      startTimestamp: new Date(service.startTimestamp).toLocaleDateString('pt-br')
    }))

    setData(result.data);
    setTotalItems(result.links.metaData.total);
    setTotalPages(result.links.metaData.lastPage);
    setIsTableLoading(false);
  };

  const loadReferences = async () => {
    const response = await getAppointmentReferences();
    setReferences(response.references)
  }

  const columns = [
    { Header: 'Id', accessor: 'serviceNumber' },
    { Header: 'Produtor/a', accessor: 'producerNameOrNickname' },
    { Header: 'Responsáveis', accessor: 'responsibles'},
    { Header: 'Data', accessor: 'startTimestamp' },
    { Header: 'Status', accessor: 'status', type: 'tag' }
  ];

  const actions = [
    { label: 'Editar', onClick: (row) => openEditModal(row.id) },
  ];

  const handleSearch = async (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
    await loadServices({page: 1, search: term});
  };
  
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
    loadServices({page: 1, filter: newFilter});
  }; 

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handleDownloadCSV = async () => {
    const csvData1 = await downloadServicesCSV({ search: searchTerm });
    if (csvData1) {
      const blob = new Blob([csvData1], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'services.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const openEditModal = async (serviceId) => {
    setSelectedServiceId(serviceId);
  };
  
  const closeModal = () => {
    setIsEditModalOpen(false);
    setSelectedServiceId(null);
  };

  useEffect(() => {
    setIsTableLoading(true);
    if (!references) loadReferences()
    if (references) loadServices({page: currentPage});
  }, [currentPage, references]);

  useEffect(() => {
    if (selectedServiceId) setIsEditModalOpen(true)
  }, [selectedServiceId])

  return (
    <div className="flex flex-col w-full h-max p-xl gap-lg">
      <div className='flex justify-between'>
        <Display size='md' style="text-green-4">Atendimentos</Display>
        <div className='flex gap-xs'>
          <ButtonPrimary
            icon='download'
            left
            onClick={handleDownloadCSV}
          />
        </div>
      </div>
      <div className='flex flex-col gap-sm'>

        {isTableLoading ? (
          <div className="flex items-center justify-center h-full">
            <span>Carregando...</span>
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            actions={actions}
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            onSearch={handleSearch}
            onFilterChange={handleFilterChange}
            defaultFilterValue=""
            searchTerm={searchTerm}
            filter={filter}
          />
        )}

        {isEditModalOpen && selectedServiceId && (
          <ServiceModal
            references={references}
            serviceId={selectedServiceId}
            onClose={closeModal}
            onSave={() => {
              closeModal();
              loadServices(currentPage);
            }}
          />
        )}
      </div>
    </div>
  );
};
export default Services;

const sessionData = getSessionData()
const currentUser = sessionData?.users[0]
const ServiceModal = ({ references, serviceId, onClose, onSave, createMode = false }) => {
  const [serviceData, setServiceData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [profilePhotoMap, setProfilePhotoMap] = useState({});
  const profilePhotoMapSyncControl = new Set();

  const loadServiceData = async (serviceId) => {
    if (createMode) return
    try {
      const response = await getServiceById(serviceId);
      if (response.data.service?.history) {
        response.data.service.history.reverse()
        response.data.service.history.forEach(message => {
          message.type = message.sender.id === currentUser.id ? 'messageSent' : 'messageReceived'
        })
        getProfilePhotos(response.data.service.history)
      }

      setServiceData(response.data);
    } catch (error) {
      console.error('Erro ao carregar atendimento:', error);
      setError('Erro ao carregar atendimento');
    }
  };

  const getProfilePhotos = async (messageList) => {
    const promises = messageList.map(async (message) => {
      if (message.type === 'changeLog') return
  
      if (!profilePhotoMapSyncControl.has(message.sender.id)) {
        profilePhotoMapSyncControl.add(message.sender.id)

        if (!message.profilePhoto) {
          setProfilePhotoMap(prev => ({ ...prev, [message.sender.id]: defaultProfilePhoto }))
          return
        }
  
        const response = await fetchProfilePhoto(message.profilePhoto)
        setProfilePhotoMap(prev => ({ ...prev, [message.sender.id]: response || defaultProfilePhoto }))
      }
    })
  
    await Promise.all(promises)
  }

  const setServiceDataForCreateMode = async () => {
    if (!createMode) return

    setServiceData({
      ...Service
    })
  }

  const handleSave = async () => {
    try {
      const serviceBody = {
          priorityLevel: serviceData.service.priorityLevel,
          serviceTypeId: serviceData.service.serviceTypeId
      }

      const response = await putService(serviceId, serviceBody)

      if (response) {
        setServiceData({});
        onSave();
      } else {
        setError('Erro ao salvar atendimento');
      }
    } catch (error) {
      console.error('Erro ao salvar atendimento:', error);
      setError('Erro ao salvar atendimento');
    }
  };

  const handleCloseOrCancel = () => {
    setServiceData({});
    onClose();
  }

  const emptyObject = (obj) => {
    return !Boolean(Object.keys(obj).length)
  }

  useEffect(() => {
    setIsLoading(true);
    if (emptyObject(serviceData)) {
      setServiceDataForCreateMode();
      loadServiceData(serviceId); 
    };
    if (!emptyObject(serviceData)) {
      setIsLoading(false);
    }
  }, [serviceId, serviceData]);

  return (
    <Modal
      title="Editar atendimento"
      onClose={handleCloseOrCancel}
      onCancel={handleCloseOrCancel}
      saveLabel="Salvar"
      onSave={handleSave}
      cancelLabel="Cancelar"
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <span>Carregando...</span>
        </div>
      ) : (
        <div className="flex flex-col gap-md w-full h-full">
          <div className='flex flex-row w-full h-full gap-md'>
            <div className='flex flex-col w-1/3'>
              <ServiceForm
                serviceData={serviceData}
                references={references}
                setServiceData={setServiceData}
                error={error}
              />
            </div>
            <div className='flex grow w-full flex-col w-2/3 border-l border-l-gray-1 p-sm'>
              <ServiceMessageHistory
                serviceData={serviceData}
                profilePhotoMap={profilePhotoMap}
              />
            </div>
          </div>
          {error && <FormError text={error} />}
        </div>
      )}
    </Modal>
  );
};

const ServiceMessageHistory = ({ serviceData, profilePhotoMap }) => {
  const history = serviceData.service.history
  const scrollRef = useRef(null)
  const prevHeight = useRef(0)

  useLayoutEffect(() => {
    const currentHeight = scrollRef.current.scrollHeight
    if (scrollRef.current.scrollTop === 0) {
      scrollRef.current.scrollTop = currentHeight - prevHeight.current
    }
    prevHeight.current = currentHeight
  }, [serviceData])

  let date = new Date()
  return (
    <div className="flex grow w-full flex-col gap-sm">
      <Body size='lg' style="text-green-3">Histórico do atendimento</Body>
      <div className='flex h-[500px] w-full grow bg-green-1'>
        <div
          className='flex-1 flex flex-col w-full grow bg-repeat'
          style={{
            backgroundImage: `url(${pattern})`,
            backgroundSize: '375px',
          }}
        >
          <div className='flex grow flex-col overflow-y-auto py-md gap-sm relative' ref={scrollRef}>
            {history.length ? history.map((item, index) =>  (
              <div key={index} className='flex flex-col gap-sm px-sm'>
                {!isSameDay(new Date(item.createdAt), date) && (date = new Date(item.createdAt)) && (
                  <div className='w-full grid justify-items-center'>
                    <div className='flex px-xs py-xxs bg-green-1 max-w-[75%] w-fit rounded-xs' style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}>
                      <Body size='md' style='text-center'>{format(new Date(date), 'dd/MM/yyyy')}</Body>
                    </div>    
                  </div>
                )}
                  <div className='flex w-full h-fit h-[32px]'>
                  {item.type === 'changeLog'
                    ? <ChangeLog item={item} />
                    : <div className={`w-full h-full grid ${item.type === 'messageReceived' ? 'justify-items-start' : 'justify-items-end'}`}>
                        <div className={`flex w-fit ${item.type === 'messageReceived' ? 'flex-row' : 'flex-row-reverse'}`} style={{ gap: 8 }}>
                          {/* Profile image */}
                          <div
                            className='h-[24px] w-[24px] rounded-full'
                            style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}
                          >
                            <img
                              src={profilePhotoMap[item.sender.id] || defaultProfilePhoto}
                              alt={`${item.sender.nickname || item.sender.name}'s profile photo`}
                              className='h-full w-full rounded-full bg-gray-2'
                            />
                          </div>
                          <Message item={item} />
                        </div>
                      </div>
                  }
                </div>
              </div>  
            )) : (
              <div className='absolute top-0 bottom-0 left-0 right-0 padding-0 items-center justify-center'>
                  <div className='flex flex-col justify-center items-center w-full h-full'>
                    <Body size='lg' style='text-center'>
                      Nenhuma mensagem  
                    </Body>
                  </div>
              </div>
            )}
          </div> 
        </div>
      </div>
    </div>
  )
}

const ServiceForm = ({ serviceData, references, setServiceData, error, createMode = false }) => {
  const toBrazilianDate = (date) => Intl.DateTimeFormat('pt-BR', { timeZone: 'America/Belem' }).format(new Date(date))
  const toBrazilianHour = (date) => Intl.DateTimeFormat('pt-BR', { hour12: false, hour:"2-digit", minute: "2-digit", timeZone: 'America/Belem' }).format(new Date(date))

  return (
    <div className="flex flex-col gap-xl">
      <div className="flex flex-col gap-sm">
        <Body size='lg' style="text-green-3">Detalhes</Body>
        <ContentField
          label='Produtor/a'
          icon='person'
          value={ serviceData.service.producerNickname || serviceData.service.producerName }
        />
        <ContentField
          label='Responsável'
          icon='badge'
          value={ serviceData.service.responsibles?.join(', ') }
        />
        <div className='flex grow flex-row justify-between'>
          <ContentField
            label='Data'
            icon='calendar_today'
            value={ toBrazilianDate(serviceData.service.startTimestamp) }
          />
          <ContentField
            label='Hora'
            icon='schedule'
            value={ toBrazilianHour(serviceData.service.startTimestamp) }
          />
        </div>
        {/* <InputSelect
          label="Tipo de atendimento"
          multiselect
          options={Object.values(references.serviceTypes)}
          value={serviceData.service.categories || []}
          icon='stacks'
          onChange={categories => setServiceData(prevState => ({
            ...prevState,
            categories
          }))}
        /> */}
      </div>
    </div>
  );
};