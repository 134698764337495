export const sanitizeObject = (targetObject, templateObject) => {
  Object.entries(targetObject).map(([key, value]) => {
    if (!(key in templateObject)) delete targetObject[key]
    if (value === undefined || value === null || value === '') delete targetObject[key]
  })
}

export const setDefaultsForPagination = (targetObject) => {
  if (targetObject.paginate === 'false') {
    delete targetObject.page
    delete targetObject.limit
    return
  }

  if (!targetObject.page) targetObject.page = 1
  if (!targetObject.limit) targetObject.limit = 20
}
