import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { format, isSameDay } from 'date-fns'

import { useChatContext } from '../../../pages/Protected/Chats'
import Message from './Message'
import ChangeLog from './ChangeLog'
import Camera from './Camera'
import Body from '../../typography/Body'
import defaultProfilePhoto from '../../../assets/images/profile.jpg'


const ChatScrollArea = ({ chatContent, setChatContent, fetchMessages, chatLength }) => {
  const { isCameraActive, chat, profilePhotoMap } = useChatContext()
  const [partialLoading, setPartialLoading] = useState(false)
  const scrollRef = useRef(null)
  const prevHeight = useRef(0)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [chat])

  useLayoutEffect(() => {
    const currentHeight = scrollRef.current.scrollHeight
    if (scrollRef.current.scrollTop === 0) {
      scrollRef.current.scrollTop = currentHeight - prevHeight.current
    }
    prevHeight.current = currentHeight

    if (partialLoading) {
      setPartialLoading(false)
    }
  }, [chatContent, isCameraActive])

  const handleScroll = () => {
    if (scrollRef.current && scrollRef.current.scrollTop === 0) {
      if (chatContent.length < chatLength) {
        setPartialLoading(true)
        fetchMessages()
      }
    }
  }

  let date = new Date()
  return (
    <div className='flex grow flex-col overflow-y-auto py-md gap-sm relative' ref={scrollRef} onScroll={handleScroll}>
      {partialLoading && (
        <div className='flex items-center px-sm justify-center h-full w-full'>
          <Body size='lg' style='text-center'>
            Carregando...
          </Body>
        </div>
      )}
      {(!isCameraActive && chatContent.length) ? chatContent.map((item, index) =>  (
        <div key={index} className='flex flex-col gap-sm px-sm'>
          {!isSameDay(new Date(item.createdAt), date) && (date = new Date(item.createdAt)) && (
            <div className='w-full grid justify-items-center'>
              <div className='flex px-xs py-xxs bg-green-1 max-w-[75%] w-fit rounded-xs' style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}>
                <Body size='md' style='text-center'>{format(new Date(date), 'dd/MM/yyyy')}</Body>
              </div>    
            </div>
          )}
            <div className='flex w-full h-fit h-[32px]'>
            {item.type === 'changeLog'
              ? <ChangeLog item={item} />
              : <div className={`w-full h-full grid ${item.type === 'messageReceived' ? 'justify-items-start' : 'justify-items-end'}`}>
                  <div className={`flex w-fit ${item.type === 'messageReceived' ? 'flex-row' : 'flex-row-reverse'}`} style={{ gap: 8 }}>
                    {/* Image */}
                    <div
                      className='h-[24px] w-[24px] rounded-full'
                      style={{ boxShadow: '0px 2px 4px rgba(21, 26, 10, 0.25)' }}
                    >
                      <img
                        src={profilePhotoMap[item.sender.id] || defaultProfilePhoto}
                        alt={`${item.sender.nickname || item.sender.name}'s profile photo`}
                        className='h-full w-full rounded-full bg-gray-2'
                      />
                    </div>
                    <Message item={item} />
                  </div>
                </div>
            }
          </div>
        </div>  
      )) : (
        <div className='absolute top-0 bottom-0 left-0 right-0 padding-0 items-center justify-center'>
          { isCameraActive && (
            <Camera setChatContent={setChatContent} />
          )}
          { !isCameraActive && (
            <div className='flex flex-col justify-center items-center w-full h-full'>
              <Body size='lg' style='text-center'>
                Nenhuma mensagem  
              </Body>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ChatScrollArea
