import React, { useState, useEffect } from 'react';
import { downloadContentsCSV, getContents, getContentReferences, getContentById, updateContent, createContent } from "../../api/contents";
import Display from '../../components/typography/Display';
import ButtonPrimary from '../../components/actions/ButtonPrimary';
import Body from '../../components/typography/Body';
import Table from '../../components/layout/Table';
import Modal from '../../components/overlay/Modal';
import InputText from '../../components/inputs/InputText';
import FormError from '../../components/inputs/FormError';
import InputSelect from '../../components/inputs/InputSelect';
import WYSIWYG from '../../components/inputs/WYSIWYG';
import { Content } from '../../types';

const Contents = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [references, setReferences] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [filter, setFilter] = useState('');
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [selectedContentId, setSelectedContentId] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const loadContents = async ({page, limit, search = searchTerm}) => {
    const result = await getContents({page, limit, search});

    result.data = result.data.map(content => ({
      ...content,
      type: content.type?.at(0),
      computedTypes: (content.types.map(typeId => references.contentTypes.find(item => item.id === typeId))).map(r => r.value),
      computedCategories: (content.categories.map(categoryId => references.contentCategories.find(item => item.id === categoryId))).map(r => r.value),
      createdAt: new Date(content.createdAt).toLocaleDateString('pt-br')
    }))

    setData(result.data);
    setTotalItems(result.links.metaData.total);
    setTotalPages(result.links.metaData.lastPage);
    setIsTableLoading(false);
  };

  const loadReferences = async () => {
    const response = await getContentReferences();
    setReferences(response.references)
  }

  const columns = [
    { Header: 'Título', accessor: 'title' },
    { Header: 'Categoria', accessor: 'computedCategories', type: 'tag' },
    { Header: 'Tipo', accessor: 'computedTypes'},
    { Header: 'Data de cadastro', accessor: 'createdAt' },
  ];

  const actions = [
    { label: 'Editar', onClick: (row) => openEditModal(row.id) },
  ];

  const handleSearch = async (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
    await loadContents({page: 1, search: term});
  };
  
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
    loadContents({page: 1, filter: newFilter});
  }; 

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handleDownloadCSV = async () => {
    const csvData1 = await downloadContentsCSV({ search: searchTerm });
    if (csvData1) {
      const blob = new Blob([csvData1], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'contents.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const openCreateModal = async () => {
    setIsCreateModalOpen(true);
  };

  const openEditModal = async (contentId) => {
    setSelectedContentId(contentId);
  };
  
  const closeModal = () => {
    setIsCreateModalOpen(false);
    setIsEditModalOpen(false);
    setSelectedContentId(null);
  };

  useEffect(() => {
    setIsTableLoading(true);
    if (!references) loadReferences()
    if (references) loadContents({page: currentPage});
  }, [currentPage, references]);

  useEffect(() => {
    if (selectedContentId) setIsEditModalOpen(true)
  }, [selectedContentId])

  return (
    <div className="flex flex-col w-full h-max p-xl gap-lg">
      <div className='flex justify-between'>
        <Display size='md' style="text-green-4">Conteúdos</Display>
        <div className='flex gap-xs'>
          <ButtonPrimary
            text="Cadastrar tutorial"
            icon='menu_book'
            left
            onClick={openCreateModal}
          />
          <ButtonPrimary
            icon='download'
            left
            onClick={handleDownloadCSV}
          />
        </div>
      </div>
      <div className='flex flex-col gap-sm'>

        {isTableLoading ? (
          <div className="flex items-center justify-center h-full">
            <span>Carregando...</span>
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            actions={actions}
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            onSearch={handleSearch}
            onFilterChange={handleFilterChange}
            defaultFilterValue=""
            searchTerm={searchTerm}
            filter={filter}
          />
        )}

        {isCreateModalOpen && (
          <ContentModal
            createMode
            references={references}
            onClose={closeModal}
            onSave={() => {
              closeModal();
              loadContents(currentPage);
            }}
          />
        )}

        {isEditModalOpen && selectedContentId && (
          <ContentModal
            references={references}
            contentId={selectedContentId}
            onClose={closeModal}
            onSave={() => {
              closeModal();
              loadContents(currentPage);
            }}
          />
        )}
      </div>
    </div>
  );
};
export default Contents;

const ContentModal = ({ references, contentId, onClose, onSave, createMode = false }) => {
  const [contentData, setContentData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const loadContentData = async (contentId) => {
    if (createMode) return
    try {
      const response = await getContentById(contentId);
      setContentData(response.data);
    } catch (error) {
      console.error('Erro ao carregar propriedade:', error);
      setError('Erro ao carregar propriedade');
    }
  };

  const setContentDataForCreateMode = async () => {
    if (!createMode) return

    setContentData({
      ...Content
    })
  }

  const handleSave = async () => {
    try {
      const contentBody = {
        content: {
          title: contentData.title,
          categories: contentData.categories,
          types: contentData.types,
          excerpt: contentData.excerpt,
          articleContent: contentData.articleContent,
          url: contentData.url
        }
      }

      const response = createMode
        ? await createContent(contentBody)
        : await updateContent(contentId, contentBody)

      if (response) {
        setContentData({});
        onSave();
      } else {
        setError('Erro ao salvar tutorial');
      }
    } catch (error) {
      console.error('Erro ao salvar tutorial:', error);
      setError('Erro ao salvar tutorial');
    }
  };

  const handleCloseOrCancel = () => {
    setContentData({});
    onClose();
  }

  const emptyObject = (obj) => {
    return !Boolean(Object.keys(obj).length)
  }

  useEffect(() => {
    setIsLoading(true);
    if (emptyObject(contentData)) {
      setContentDataForCreateMode();
      loadContentData(contentId); 
    };
    if (!emptyObject(contentData)) setIsLoading(false);
  }, [contentId, contentData]);

  useEffect(() => {

  })

  return (
    <Modal
      title="Editar conteúdo"
      onClose={handleCloseOrCancel}
      onCancel={handleCloseOrCancel}
      saveLabel="Salvar"
      onSave={handleSave}
      cancelLabel="Cancelar"
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <span>Carregando...</span>
        </div>
      ) : (
        <div className="flex flex-col gap-xl">
          <ContentForm
            contentData={contentData}
            references={references}
            setContentData={setContentData}
            error={error}
          />
          {error && <FormError text={error} />}
        </div>
      )}
    </Modal>
  );
};

const ContentForm = ({ contentData, references, setContentData, error, createMode = false }) => {
  return (
    <div className="flex flex-col gap-xl">
      <div className="flex flex-col gap-xs">
        <Body size='lg' style="text-green-3">Informações principais</Body>
        <InputText
          label="Título do tutorial"
          value={contentData.title}
          icon='menu_book'
          onChangeText={title => setContentData(prevState => ({
            ...prevState,
            title
          }))}
        />
        <InputText
          label="Descrição"
          value={contentData.excerpt}
          icon='menu'
          onChangeText={excerpt => setContentData(prevState => ({
            ...prevState,
            excerpt
          }))}
        />
        <InputSelect
          label="Categoria"
          multiselect
          options={Object.values(references.contentCategories)}
          value={contentData.categories || []}
          icon='stacks'
          onChange={categories => setContentData(prevState => ({
            ...prevState,
            categories
          }))}
        />
        <InputSelect
          label="Tipo de tutorial"
          multiselect
          options={Object.values(references.contentTypes)}
          value={contentData.types || []}
          icon='book_2'
          onChange={ types => setContentData(prevState => ({
            ...prevState,
            types
          }))}
        />
        {(
          (contentData.types?.includes(2) || contentData.types?.includes(3)) &&
            <WYSIWYG
              value={contentData.articleContent || ''}
              onChange={articleContent => setContentData(prevState => ({
                ...prevState,
                articleContent
              }))}
            />
        )}
      </div>
      {error && <FormError text={error} />}
    </div>
  );
};